import { css } from '@emotion/react'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TrendTable from '../eurex/_private/component/TrendTable'

export const daddy960_tc1688_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      ${TrendTable.classes.title} {
        background-color: #202026;
        border-radius: 4px;
      }

      ${(TrendTable.classesFill.upFill = css`
        &.dark {
          color: #ff0000;
        }
      `)}

      ${(TrendTable.classesFill.dnFill = css`
        &.dark {
          color: #00ff00;
        }
      `)}
    }
  `
}
