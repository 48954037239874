import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import {
  SymbolListChangeFill,
  SymbolPlatformChangeFill,
} from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill'
import WatchListAddButton from '~/modules/symbolPlatform/WatchListAddButton'
import { StockSelectionBoard } from './component/StockSelectionBoard'

const symbols = [
  '2356',
  '1590',
  '2303',
  '2609',
  '3481',
  '1605',
  '3231',
  '6282',
  '2618',
  '2330',
  '1905',
  '2344',
  '2353',
  '2610',
  '2602',
  '3437',
  '2426',
]

// 四向選股
// 多方
// 17/88日均線金叉 股價在所有均線之上
// 17/88日均線金叉 股價在17日均線之下
// 近三季度營收或毛利或淨利率QoQ%或YoY%增長
// 近三周800張以上大戶比率增加 10張未滿比率下降
// 日線MACD柱體顏色由藍轉紅

// 空方
// 17/88日均線死叉 股價在所有均線之下
// 17/88日均線死叉 股價在17日均線之上
// 近三季度營收或毛利或淨利率QoQ%或YoY%下降
// 近三周800張以上大戶比率增加 10張未滿比率下降
// 日線MACD柱體顏色由紅轉藍

export const Revive_SidePane2 = memo<ReactProps>(function Revive_SidePane2() {
  return (
    <styleds.container>
      {/* <styleds.searchTitle>個股搜尋</styleds.searchTitle>
      <styleds.searchContent>
        <SymbolSearch />
      </styleds.searchContent> */}

      <styleds.searchTitle>選股</styleds.searchTitle>

      <StockSelectionBoard />

      <div
        css={css`
          ${fill_vertical_all_center};
          width: 100%;
          height: calc(100% - 290px);
        `}
      >
        <SymbolListChangeFill
          symbol={symbols}
          watchListGroup='stocknerve_group_1'
        />
      </div>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 4px;
  `,
  watchlistButton: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
  `,
  searchTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    background-color: #494b57;
    border-radius: 4px;
  `,
  searchContent: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: #252525;
  `,
  symbolListContent: styled.div`
    width: 100%;
    height: calc(100% - 400px);
  `,
}
