import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Divider } from '@mantine/core'
import { memo, useState } from 'react'
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { revive_store } from '../revive_store'

const TypeBotton = memo<ReactProps<{ type: 'long' | 'short' }>>(function TypeBotton(props) {
  const state = useSnapshot(revive_store)
  const displayType = props.type === 'long' ? '多方選股' : '空方選股'
  const selected = props.type === state.stockSelectionType

  return (
    <styleds.button
      selected={selected}
      className={props.type}
      onClick={() => (revive_store.stockSelectionType = props.type)}
    >
      {displayType}
    </styleds.button>
  )
})

const SelectCard = memo<ReactProps>(function SelectCard(props_) {
  const [selected, setSelected] = useState(false)
  const state = useSnapshot(revive_store)

  return (
    <styleds.selectCard
      selected={selected}
      className={state.stockSelectionType}
      onClick={() => setSelected(!selected)}
    >
      {selected ? <BsCheckCircleFill /> : <BsCheckCircle />}
      &nbsp;
      {props_.children}
    </styleds.selectCard>
  )
})

export const StockSelectionBoard = memo<ReactProps>(function StockSelectionBoard() {
  const state = useSnapshot(revive_store)

  return (
    <styleds.container>
      <styleds.typeContent>
        <TypeBotton type={'long'}>多方</TypeBotton>
        <TypeBotton type={'short'}>空方</TypeBotton>
      </styleds.typeContent>
      <styleds.title>
        <Divider
          css={css`
            width: 100%;
          `}
          color={'#efefef'}
          my='sm'
          labelPosition='center'
          label='條件選擇'
        />
      </styleds.title>

      {state.stockSelectionType === 'long' && (
        <styleds.itemContent>
          <SelectCard>近三季度營收或毛利或淨利率QoQ%或YoY%上升</SelectCard>
          <SelectCard>近三周800張以上大戶比率增加</SelectCard>
          <SelectCard>日線MACD柱體顏色由藍轉紅</SelectCard>
          <SelectCard>17/88日均線金叉</SelectCard>
        </styleds.itemContent>
      )}
      {state.stockSelectionType === 'short' && (
        <styleds.itemContent>
          <SelectCard>近三季度營收或毛利或淨利率QoQ%或YoY%下降</SelectCard>
          <SelectCard>近三周800張以上大戶比率減少</SelectCard>
          <SelectCard>日線MACD柱體顏色由紅轉藍</SelectCard>
          <SelectCard>17/88日均線死叉</SelectCard>
        </styleds.itemContent>
      )}
    </styleds.container>
  )
})

const styleds = {
  //button---------------------------
  button: styled.div<{ selected: boolean }>`
    ${fill_vertical_all_center};
    background-color: #2f2f2f;
    border: 1px solid ${props => (props.selected === true ? '#acacac' : '#686868')};
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    &.long {
      background-color: ${props => (props.selected === true ? '#551100' : '#2f2f2f')};
    }
    &.short {
      background-color: ${props => (props.selected === true ? '#005500' : '#2f2f2f')};
    }
    transition: 0.3s;
  `,
  //board----------------------------
  selectCard: styled.div<{ selected: boolean }>`
    ${fill_horizontal_cross_center};
    height: 42px;
    border: 1px solid ${props => (props.selected ? '#acacac' : '#454545')};
    color: ${props => (props.selected ? '#fafafa' : '#aaaaaa')};
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      margin-left: 4px;
    }
    &.long {
      background-color: ${props => (props.selected ? '#563a3a' : '#3a3a3f')};
    }
    &.short {
      background-color: ${props => (props.selected ? '#41553a' : '#3a3a3f')};
    }
  `,

  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #25262a;
    height: 252px;
    padding: 2px;
    user-select: none;
  `,
  title: styled.div`
    ${fill_vertical_all_center};
    height: 30px;
    font-size: 14px;
  `,
  typeContent: styled.div`
    ${fill_horizontal_all_center};
    height: 36px;
    gap: 4px;
    padding: 2px;
  `,
  itemContent: styled.div`
    ${fill_vertical_cross_center};
    height: calc(100% - 66px);
    gap: 4px;
    padding: 0px 2px;
  `,
}
