import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

import { SymbolPlatformChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'

const symbols = ['2356', '1590', '2618', '2330', '1905', '2344', '3437', '2426']

export const Revive_SidePane1 = memo<ReactProps>(function Revive_SidePane1() {
  return (
    <styleds.container>
      <styleds.card height={'calc(100% - 0px)'}>
        <SymbolPlatformChangeFill
          symbol={symbols}
          watchListGroup='stocknerve_group_1'
        />
      </styleds.card>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
  card: styled.div<{ height: number | string }>`
    ${fill_vertical_all_center};
    height: ${props => props.height};
    background-color: #25262a;
    border-radius: 8px;
  `,
}
