import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { volume } from '~/trades/indicators/lung88988/volume'
import { win988_intraday } from '~/trades/indicators/win988/win988_intraday'
import { win988_intradayAM } from '~/trades/indicators/win988/win988_intradayAM'
import { win988_intradayPM } from '~/trades/indicators/win988/win988_intradayPM'
import { win988_intradayShort } from '~/trades/indicators/win988/win988_intradayShort'
import { win988_position15M } from '~/trades/indicators/win988/win988_position15M'
import { win988_position1H } from '~/trades/indicators/win988/win988_position1H'
import { win988_position30M } from '~/trades/indicators/win988/win988_position30M'
import { win988_positionChip1H } from '~/trades/indicators/win988/win988_positionChip1H'

export const win988_strategiesDayTradeGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '極短當沖',
      indicators: [win988_intradayShort],
      symbol: 'TX-1',
      interval: '1',
      panesRatio: 66,
    },
    {
      displayName: '日內當沖',
      indicators: [win988_intraday],
      symbol: 'TX-1',
      interval: '3',
      panesRatio: 66,
    },
    {
      displayName: '日盤當沖',
      indicators: [win988_intradayAM],
      symbol: 'TXAM-1',
      interval: '3',
      panesRatio: 66,
    },
    {
      displayName: '夜盤當沖',
      indicators: [win988_intradayPM],
      symbol: 'TXPM-1',
      interval: '5',
      panesRatio: 66,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const win988_strategiesBandGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '短線波段',
      indicators: [win988_position15M],
      symbol: 'TX-1',
      interval: '15',
      panesRatio: 66,
    },
    {
      displayName: '區間波段',
      indicators: [win988_position30M],
      symbol: 'TX-1',
      interval: '30',
      panesRatio: 66,
    },
    {
      displayName: '籌碼波段',
      indicators: [win988_positionChip1H],
      symbol: 'TX-1',
      interval: '60',
      panesRatio: 66,
    },
    {
      displayName: '長線波段',
      indicators: [win988_position1H],
      symbol: 'TX-1',
      interval: '60',
      panesRatio: 66,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const win988_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded]

  store.charting.initStrategy({
    configs: [...win988_strategiesDayTradeGroup.main, ...win988_strategiesBandGroup.main],
  })
}
