import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'

export const win988_positionChip1H = createIndicator({
  id: 'win988-position-chip-1h',
  displayName: '籌碼波段',

  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#bs_indicator_acc'
      context.new_sym(symbol, this.PineJS.Std.period(context))
    },
    main(context, inputCallback) {
      const time = this._context.new_unlimited_var(this._context.symbol.time)
      context.select_sym(1)

      const largeChipValue = this._context
        .new_unlimited_var(this.PineJS.Std.high(context))
        .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0)

      const retailChipValue = this._context
        .new_unlimited_var(this.PineJS.Std.low(context))
        .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0)

      const chipValue = this._context
        .new_unlimited_var(this.PineJS.Std.open(context))
        .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0)

      context.select_sym(0)
      const STOP_LOSS = 80
      const TAKE_PROFIT = 160
      const position = context.new_var()
      const entryPrice = context.new_var()
      const exitPrice = context.new_var()
      const entriesToday = context.new_var()

      const close = this.ohlc.closeArray
      const open = this.ohlc.openArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volume = context.new_var(this.PineJS.Std.volume(context))
      const datetime = dayAPI(this.PineJS.Std.time(context))
      const chip = context.new_var(chipValue)
      const largeChip = context.new_var(largeChipValue)
      const retailChip = context.new_var(retailChipValue)

      position.get(1)
      entriesToday.get(100)
      entryPrice.get(100)
      exitPrice.get(100)

      close.get(100)
      open.get(100)
      high.get(100)
      low.get(100)
      volume.get(100)
      chip.get(100)
      retailChip.get(100)
      largeChip.get(100)

      //#region indicators
      const maLong = this.average(close, 13)
      const maShort = this.average(close, 21)
      const stochastic = this.stochastic(9, 3, 3)
      //#endregion

      const longEntry =
        chipValue > this.average(chip, 21) &&
        stochastic.k > 60 &&
        close.get(0) > maLong &&
        close.get(1) < maLong
      const shortEntry =
        chipValue < this.average(chip, 21) &&
        stochastic.k < 40 &&
        close.get(0) < maShort &&
        close.get(1) > maShort

      // ---------------------------------------- Entry ----------------------------------------
      if (longEntry) position.set(1)
      if (shortEntry) position.set(-1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entriesToday.set(entriesToday.get(0) + 1)
        entryPrice.set(close.get(0))
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(close.get(0))
        entriesToday.set(entriesToday.get(0) + 1)
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        // Take Profit
        if (high.get(0) > entryPrice.get(1) + TAKE_PROFIT) position.set(0)

        // stoploss
        if (low.get(0) < entryPrice.get(1) - STOP_LOSS) position.set(0)

        //13:30
        if (datetime.hour() === 4 && datetime.minute() === 50) position.set(0)
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        //固定停利
        if (low.get(0) < entryPrice.get(1) - TAKE_PROFIT) position.set(0)

        // stop loss
        if (high.get(0) > entryPrice.get(1) + STOP_LOSS) position.set(0)

        //13:30
        if (datetime.hour() === 4 && datetime.minute() === 50) position.set(0)
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 3 : 4
      }
      return [
        (position.get(1) === 0 && position.get(0) > 0) || NaN,
        (position.get(1) > 0 && position.get(0) === 0) || NaN,
        (position.get(1) === 0 && position.get(0) < 0) || NaN,
        (position.get(1) < 0 && position.get(0) === 0) || NaN,
        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'chars',
      },
      {
        id: 'exitLongFlag',
        type: 'chars',
      },
      {
        id: 'entryShortFlag',
        type: 'chars',
      },
      {
        id: 'exitShortFlag',
        type: 'chars',
      },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        exitLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
        entryShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
        exitShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
      },
    },
    styles: {
      entryLongFlag: {
        title: '進場點(多)',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單進場',
      },
      exitLongFlag: {
        title: '出場點(多)',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '多單出場',
      },
      entryShortFlag: {
        title: '進場點(空)',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單進場',
      },
      exitShortFlag: {
        title: '出場點(空)',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '空單出場',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
